/* colours */
$c-bg: #FAFAFA;
$c-text: #475569;
$c-text-light: #64748b;
$c-border: #cbd5e1;
$c-border-dark: #c3cbd4;
$c-primary: #73b0f4;
$c-dark: #1f2937;
$c-light: #b9b9b9;

// social media
$c-facebook: #3b5998;
$c-twitter: #55acee;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;
$c-microsoft: #d73a17;

$c-red: #d95565;
$c-green: #10b981;
$c-green-dark: #1a912e;
$c-blue: #73b0f4;
$c-darkblue: #3a4488;
$c-orange: #f0aa61;
$c-tint: #fbfbff;
$c-purple: #6363ac;
$c-darkpurple: #556cb6;
