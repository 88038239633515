@import "../../components/global.scss";

.plan_options {
    table,
    th,
    td {
        @apply border border-slate-300 border-collapse;
    }

    table {
        tr {
            td {
                @apply p-3;
            }
        }
    }
}
