@import "components/global.scss";


.app {
  padding-top: $scale2;
  padding-bottom: $scale2;
  padding-inline: $scale-2;
  min-height: 100%;
  background-color: $c-bg;
  transition: all 0.2s ease-out;
  width: 100%;

  @media (min-width: $mobile) {
    width: calc(100% - $scale7);
    margin-left: auto;
  }
}
