@import "components/global.scss";

.steps {
  @apply flex flex-col md:flex-row md:items-center flex-wrap justify-evenly;
  gap: 1.5rem;
  list-style: none;
  text-align: center;

  li {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    white-space: nowrap;
    width: fit-content;

    svg {
      min-width: 1.25rem;
      min-height: 1.25rem;
    }

    a {
      padding-top: 3em;
      text-decoration: none;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.complete {
  svg {
    stroke: $c-green-dark;
    stroke-width: 2.5px;
  }

  a,
  span {
    color: $c-green-dark;
    @apply font-semibold;
  }
}
