@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply text-[15px] sm:text-[13px] 2xl:text-[16px];
}

body {
  overflow-x: hidden;
}

html, #root, body {

  height: 100%;

}

@layer base {
  body {

    @apply text-gray-600;

  }
  *, *:after, *:before {

    @apply bg-no-repeat;

  }
  article p {

    @apply mb-3;

  }
}

@layer components {
  table tbody tr:last-child td {
    
    @apply border-0 !important;

  }
}